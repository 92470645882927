var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-bg-white tw-text-xl tw-py-[58px] tw-px-[62px] tw-rounded-[15px]"},[(_vm.isLoading)?[_c('div',{staticClass:"my-2 text-center text-danger"},[_c('b-spinner',{staticClass:"mr-1 align-middle"}),_c('strong',[_vm._v("Sedang memuat data...")])],1)]:_c('div',[_c('p',{staticClass:"tw-text-[#46505C] tw-font-semibold tw-text-2xl"},[_vm._v("Detail Soal")]),_c('div',{staticClass:"tw-flex tw-mt-8 tw-flex-col tw-gap-6"},[_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Jenis Soal")]),_c('p',{staticClass:"tw-text-[#6F7981] tw-capitalize"},[_vm._v(" "+_vm._s(_vm.data.jenis_soal === "pilihan_ganda" ? "pilihan ganda" : _vm.data.jenis_soal)+" ")])]),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Mata Pelajaran")]),_c('p',{staticClass:"tw-text-[#6F7981]"},[_vm._v(_vm._s(_vm.data.mata_pelajaran))])]),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Kategori Soal")]),_c('p',{staticClass:"tw-text-[#6F7981]"},[_vm._v(_vm._s(_vm.data.kategori_soal))])]),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Paket")]),_c('p',{staticClass:"tw-text-[#6F7981]"},[_vm._v(_vm._s(_vm.data.paket))])]),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Indeks Kesulitan")]),_c('p',{staticClass:"tw-text-[#6F7981]"},[_vm._v(_vm._s(_vm.data.index_kesulitan))])]),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Pertanyaan")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pertanyaan}})],1),(_vm.data.jenis_soal === 'pilihan_ganda')?_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Pilihan Jawaban")]),_c('div',{class:("tw-flex tw-gap-2 tw-items-center mb-1 " + (_vm.data.kunci_jawaban === 'A'
              ? 'tw-text-[#5022E2]'
              : 'tw-text-[#6F7981]'))},[_c('span',[_vm._v("A.")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pil_a}}),_c('span',[_vm._v("(bobot nilai: "+_vm._s(_vm.data.bobot_nilai_a)+")")])],1),_c('div',{class:("tw-flex tw-gap-2 tw-items-center mb-1 " + (_vm.data.kunci_jawaban === 'B'
              ? 'tw-text-[#5022E2]'
              : 'tw-text-[#6F7981]'))},[_c('span',[_vm._v("B.")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pil_b}}),_c('span',[_vm._v("(bobot nilai: "+_vm._s(_vm.data.bobot_nilai_b)+")")])],1),_c('div',{class:("tw-flex tw-gap-2 tw-items-center mb-1 " + (_vm.data.kunci_jawaban === 'C'
              ? 'tw-text-[#5022E2]'
              : 'tw-text-[#6F7981]'))},[_c('span',[_vm._v("C.")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pil_c}}),_c('span',[_vm._v("(bobot nilai: "+_vm._s(_vm.data.bobot_nilai_c)+")")])],1),_c('div',{class:("tw-flex tw-gap-2 tw-items-center mb-1 " + (_vm.data.kunci_jawaban === 'D'
              ? 'tw-text-[#5022E2]'
              : 'tw-text-[#6F7981]'))},[_c('span',[_vm._v("D.")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pil_d}}),_c('span',[_vm._v("(bobot nilai: "+_vm._s(_vm.data.bobot_nilai_d)+")")])],1),_c('div',{class:("tw-flex tw-gap-2 tw-items-center mb-1 " + (_vm.data.kunci_jawaban === 'E'
              ? 'tw-text-[#5022E2]'
              : 'tw-text-[#6F7981]'))},[_c('span',[_vm._v("E.")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pil_e}}),_c('span',[_vm._v("(bobot nilai: "+_vm._s(_vm.data.bobot_nilai_e)+")")])],1)]):_vm._e(),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Kunci Jawaban")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.kunci_jawaban_essay}})],1),(_vm.data.jenis_soal === 'checkbox')?_c('table-checkbox',{attrs:{"data":_vm.dataCheckbox,"disabled":true}}):_vm._e(),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Pembahasan")]),_c('vue-mathjax',{attrs:{"safe":false,"formula":_vm.data.pembahasan_text}})],1),_c('div',[_c('p',{staticClass:"tw-font-semibold"},[_vm._v("Video Pembahasan")]),_c('b-link',{attrs:{"href":_vm.data.pembahasan_video,"target":"_blank"}},[_vm._v(_vm._s(_vm.data.pembahasan_video))])],1)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }