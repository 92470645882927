<template>
  <div
    class="tw-bg-white tw-text-xl tw-py-[58px] tw-px-[62px] tw-rounded-[15px]"
  >
    <template v-if="isLoading">
      <div class="my-2 text-center text-danger">
        <b-spinner class="mr-1 align-middle"></b-spinner>
        <strong>Sedang memuat data...</strong>
      </div>
    </template>

    <div v-else>
      <p class="tw-text-[#46505C] tw-font-semibold tw-text-2xl">Detail Soal</p>

      <div class="tw-flex tw-mt-8 tw-flex-col tw-gap-6">
        <div>
          <p class="tw-font-semibold">Jenis Soal</p>
          <p class="tw-text-[#6F7981] tw-capitalize">
            {{
              data.jenis_soal === "pilihan_ganda"
                ? "pilihan ganda"
                : data.jenis_soal
            }}
          </p>
        </div>

        <div>
          <p class="tw-font-semibold">Mata Pelajaran</p>
          <p class="tw-text-[#6F7981]">{{ data.mata_pelajaran }}</p>
        </div>

        <div>
          <p class="tw-font-semibold">Kategori Soal</p>
          <p class="tw-text-[#6F7981]">{{ data.kategori_soal }}</p>
        </div>

        <div>
          <p class="tw-font-semibold">Paket</p>
          <p class="tw-text-[#6F7981]">{{ data.paket }}</p>
        </div>

        <div>
          <p class="tw-font-semibold">Indeks Kesulitan</p>
          <p class="tw-text-[#6F7981]">{{ data.index_kesulitan }}</p>
        </div>

        <div>
          <p class="tw-font-semibold">Pertanyaan</p>
          <vue-mathjax :safe="false" :formula="data.pertanyaan"></vue-mathjax>
        </div>

        <!-- render kunci_jawaban pilihan ganda -->
        <div v-if="data.jenis_soal === 'pilihan_ganda'">
          <p class="tw-font-semibold">Pilihan Jawaban</p>

          <div
            :class="`tw-flex tw-gap-2 tw-items-center mb-1 ${
              data.kunci_jawaban === 'A'
                ? 'tw-text-[#5022E2]'
                : 'tw-text-[#6F7981]'
            }`"
          >
            <span>A.</span>
            <vue-mathjax :safe="false" :formula="data.pil_a"></vue-mathjax>
            <span>(bobot nilai: {{ data.bobot_nilai_a }})</span>
          </div>
          <div
            :class="`tw-flex tw-gap-2 tw-items-center mb-1 ${
              data.kunci_jawaban === 'B'
                ? 'tw-text-[#5022E2]'
                : 'tw-text-[#6F7981]'
            }`"
          >
            <span>B.</span>
            <vue-mathjax :safe="false" :formula="data.pil_b"></vue-mathjax>
            <span>(bobot nilai: {{ data.bobot_nilai_b }})</span>
          </div>
          <div
            :class="`tw-flex tw-gap-2 tw-items-center mb-1 ${
              data.kunci_jawaban === 'C'
                ? 'tw-text-[#5022E2]'
                : 'tw-text-[#6F7981]'
            }`"
          >
            <span>C.</span>
            <vue-mathjax :safe="false" :formula="data.pil_c"></vue-mathjax>
            <span>(bobot nilai: {{ data.bobot_nilai_c }})</span>
          </div>
          <div
            :class="`tw-flex tw-gap-2 tw-items-center mb-1 ${
              data.kunci_jawaban === 'D'
                ? 'tw-text-[#5022E2]'
                : 'tw-text-[#6F7981]'
            }`"
          >
            <span>D.</span>
            <vue-mathjax :safe="false" :formula="data.pil_d"></vue-mathjax>
            <span>(bobot nilai: {{ data.bobot_nilai_d }})</span>
          </div>
          <div
            :class="`tw-flex tw-gap-2 tw-items-center mb-1 ${
              data.kunci_jawaban === 'E'
                ? 'tw-text-[#5022E2]'
                : 'tw-text-[#6F7981]'
            }`"
          >
            <span>E.</span>
            <vue-mathjax :safe="false" :formula="data.pil_e"></vue-mathjax>
            <span>(bobot nilai: {{ data.bobot_nilai_e }})</span>
          </div>
        </div>

        <!-- Kunci Jawaban for soal_jenis essay -->
        <div>
          <p class="tw-font-semibold">Kunci Jawaban</p>
          <vue-mathjax
            :safe="false"
            :formula="data.kunci_jawaban_essay"
          ></vue-mathjax>
        </div>

        <table-checkbox
          v-if="data.jenis_soal === 'checkbox'"
          :data="dataCheckbox"
          :disabled="true"
        />

        <div>
          <p class="tw-font-semibold">Pembahasan</p>
          <vue-mathjax
            :safe="false"
            :formula="data.pembahasan_text"
          ></vue-mathjax>
        </div>

        <div>
          <p class="tw-font-semibold">Video Pembahasan</p>
          <b-link :href="data.pembahasan_video" target="_blank">{{
            data.pembahasan_video
          }}</b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import { BSpinner, BLink } from "bootstrap-vue";
import { VueMathjax } from "vue-mathjax";

import repository from "@repofactory";
const repoSoal = repository.get("soalRepository");

import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TableCheckbox from "@/@core/components/base/TableCheckbox.vue";

export default defineComponent({
  components: {
    BSpinner,
    VueMathjax,
    BLink,
    ToastificationContent,
    TableCheckbox,
  },
  setup() {
    const { route } = useRouter();
    const toast = useToast();

    const data = ref({});

    const dataCheckbox = computed(() => {
      return JSON.parse(data.value.kunci_jawaban_checkbox).map((item) => ({
        pertanyaan: item.pertanyaan,
        selected: Object.keys(
          item.jawaban.find((kategoris) => {
            const key = Object.keys(kategoris)[0];
            return kategoris[key] === true;
          })
        )[0],
        jawaban: item.jawaban.map((item) => {
          const key = Object.keys(item)[0];
          return key;
        }),
      }));
    });
    const isLoading = ref(false);

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const fetchDetail = async () => {
      try {
        isLoading.value = true;
        const res = await repoSoal.show(route.value.params.id);
        data.value = res.data.data;
        isLoading.value = false;
      } catch (error) {
        showToast(
          "Error",
          error.response.data.message,
          "danger",
          "AlertTriangleIcon"
        );
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchDetail();
    });

    return {
      // state
      data,
      isLoading,
      dataCheckbox,
    };
  },
});
</script>
